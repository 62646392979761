<template>
  <div align="center">
    <br>
    <div class="card col-md-8 rounded shadow-lg" align="left">
      <div class="card-body">
        <div class="card-title">
          <h1>Exportar datos</h1>
        </div>
        <br>
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="searchDcmnts({'rango':'todo'})">Exportar</button>
                <div v-if="showProgress" style="text-align:center">
                <v-progress-circular
                    :size="50"
                    :width="5"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </div>
                {{ progress }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Adjuntos:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosAdjuntos">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Dependencias:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('dependencias')">Iniciar</button>
                <div v-if="showProgress" style="text-align:center">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Series:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('series')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Tablas:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('tablas')">Iniciar</button>
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <router-link :to="{ name: 'Home' }" class="btn btn-secondary">Cancelar</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
    {{ cambios }}
    {{ errores }}

  </div>
</template>

<script>
  //import { VProgressCircular } from 'vuetify/lib';

  //import toastr from 'toastr';
  import moment from 'moment';
  import XLSX from 'xlsx';

  import { wait } from '../../utils';

  //feather.replace();

  export default {
    components: {
      //VProgressCircular
    },
    data(){
      return{
        datos: [],
        message: '',
        errores: [],
        tpotablas: [],
        dependencias: [],
        series: [],
        tpousus: [],
        tpocors: [],
        tpodocs: [],
        usuarios: [],
        tpoarchs: [],
        indices: [],
        estados: [],
        salas: [],
        count: 0,
        cambios: [],
        limit1: 0,
        limit2: 0,
        progress: '',
        showProgress: false
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      companydata() {
        return this.$store.state.companydata
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.fetchTpotabla();
      this.fetchDependencia();
      this.fetchSerie();
      this.fetchTpousu();
      this.fetchTpocor();
      this.fetchTpodoc();
      this.fetchUsuario();
      this.fetchTpoarch();
      this.fetchIndice();
      this.fetchEstado();
      this.fetchSala();

    },
    methods: {
      fetchTpotabla()
      {
        let uri = '/tpotablas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpotablas = response.data;
        });
      },
      fetchDependencia()
      {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.dependencias = response.data;
        });
      },
      fetchSerie()
      {
        let uri = '/series/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.series = response.data;
        });
      },
      fetchTpousu()
      {
        let uri = '/tpousus/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpousus = response.data;
        });
      },
      fetchTpocor()
      {
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpocors = response.data;
        });
      },
      fetchTpodoc()
      {
        let uri = '/tpodocs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpodocs = response.data;
        });
      },
      fetchUsuario()
      {
        let uri = '/usuarios/' + String(this.$store.state.company);
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          this.usuarios = response.data;
        });
      },
      fetchTpoarch()
      {
        let uri = '/tpoarchs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpoarchs = response.data;
        });
      },
      fetchIndice()
      {
        let uri = '/indices/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.indices = response.data;
        });
      },
      fetchEstado()
      {
        let uri = '/estados/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.estados = response.data;
        });
      },
      fetchSala()
      {
        let uri = '/salas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.salas = response.data;
        });
      },
    searchDcmnts(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/export';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
            var list = response.data;

            /*
            for (var i = 0; i < list.length; i++){
                for (var j = 0; j < this.tpocors.length; j++){
                if (list[i].tcr_id == this.tpocors[j]._id){
                    list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                    j = this.tpocors.length;
                }
                }
                
                for (var j = 0; j < this.dependencias.length; j++){
                if (list[i].dep_id == this.dependencias[j]._id){
                    list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                    j = this.dependencias.length;
                }
                }

                for (var j = 0; j < this.series.length; j++){
                if (list[i].ser_id == this.series[j]._id){
                    list[i].ser_nmbre = this.series[j].ser_nmbre;
                    j = this.series.length;
                }
                }

                for (var j = 0; j < this.usuarios.length; j++){
                if (list[i].usu_id == this.usuarios[j]._id){
                    list[i].usu_cdgo = this.usuarios[j].usu_cdgo;
                    j = this.usuarios.length;
                }
                }

            }
            */

        /*
            let sortBy = [{ prop:'dep_nmbre', direction: 1 },
                { prop:'usu_cdgo', direction: 1 },
                { prop:'tcr_nmbre', direction: 1 }                
            ];

            list.sort(function(a,b){
            let i = 0, result = 0;
            while(i < sortBy.length && result === 0) {
                result = sortBy[i].direction*(a[ sortBy[i].prop ].toString() < b[ sortBy[i].prop ].toString() ? -1 : (a[ sortBy[i].prop ].toString() > b[ sortBy[i].prop ].toString() ? 1 : 0));
                i++;
            }
            return result;
            });
        */

            var report = [];
            for (var i = 0; i < list.length; i++){
                for (var j = 0; j < this.tpocors.length; j++){
                if (list[i].tcr_id == this.tpocors[j]._id){
                    list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                    j = this.tpocors.length;
                }
                }
                
                for (var j = 0; j < this.dependencias.length; j++){
                if (list[i].dep_id == this.dependencias[j]._id){
                    list[i].dep_cdgo = this.dependencias[j].dep_cdgo;
                    list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                    j = this.dependencias.length;
                }
                }

                for (var j = 0; j < this.series.length; j++){
                if (list[i].ser_id == this.series[j]._id){
                    list[i].ser_cdgo = this.series[j].ser_cdgo;
                    list[i].ser_nmbre = this.series[j].ser_nmbre;
                    j = this.series.length;
                }
                }

                for (var j = 0; j < this.usuarios.length; j++){
                if (list[i].usu_id == this.usuarios[j]._id){
                    list[i].usu_nmbre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
                    j = this.usuarios.length;
                }
                }

                let item = {};
                item.id = list[i]._id;
                item.tcr_nmbre = list[i].tcr_nmbre;
                item.dcm_ano = list[i].dcm_ano;
                item.dcm_cdgo = list[i].dcm_cdgo;
                item.dcm_fcharad = list[i].dcm_fcharad;
                item.dcm_entidad = list[i].dcm_entidad;
                item.dcm_ciudad = list[i].dcm_ciudad;
                item.dcm_asunto = list[i].dcm_asunto;
                item.dcm_anexos = list[i].dcm_anexos;
                item.dep_cdgo = list[i].dep_cdgo;
                item.dep_nmbre = list[i].dep_nmbre;
                item.ser_cdgo = list[i].ser_cdgo;
                item.ser_nmbre = list[i].ser_nmbre;
                item.dcm_rspsta = list[i].dcm_rspsta ? 'Si' : 'No';
                item.dcm_id_rs = list[i].dcm_id_rs;
                //item.usu_nmbre = list[i].usu_nmbre;
                //item.indices = JSON.stringify(list[i].indices);
                //item.adjuntos = JSON.stringify(list[i].adjuntos);
                //item.seguimientos = JSON.stringify(list[i].seguimientos);

                this.progress = (i+1)/list.length*100 + '%';
                report.push(item);
            }

            var fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');

            var tableId = 'Documentos';
            var top = [
                [this.companydata.cmp_nmbre],
                [fecha],
                [],
                ["Documentos"],
                //[this.rango],
                [],
                [],
                ["Id", "Clase documento", "Año", "Consecutivo", "Fecha Radicación", "Entidad", "Ciudad", "Asunto", "Anexos", "Código Dependencia", "Nombre Dependencia", "Código Serie", "Nombre Serie", "Necesita respuesta", "Respuesta"]
            ];
            var origin = "A8"

            this.exportReport(report, tableId, top, origin)

            this.showProgress = false;
            this.message = "Documentos completados";
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    exportReport(report, tableId, top, origin){
      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet(top);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: origin });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

      // END METHODS
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>